import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { cspUtilActions, cspUtilSelectors } from '@appState';
import { DIALOG_NO } from '@models/constants';
import { select, Store } from '@ngrx/store';
import { CuiButtonComponent, CuiButtonCtaComponent, CuiCardModule, CuiInputComponent, CuiValidators } from 'cui-components';
import { Observable, tap } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';

type MpnIdCheckForm = {
  mpnId: FormControl<string>;
  partnerName: FormControl<string>;
};

@Component({
  imports: [
    CommonModule,
    CuiCardModule,
    CuiButtonCtaComponent,
    CuiButtonComponent,
    CuiInputComponent,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
  ],
  selector: 'hui-mpn-id-check-dialog',
  template: `
    <cui-card
      *ngIf="{
        isLoading: (isLoading$ | async)!,
        retrievedPartnerName: (retrievedPartnerName$ | async)
      } as obs$"
    >
      <cui-card-header [showCloseBtn]="true" (close)="onCancelClick()">
        <h3>Check MPN ID</h3>
      </cui-card-header>
      <cui-card-body>
        <form [formGroup]="form">
          <cui-input [label]="'Mpn Id'" formControlName="mpnId" [required]="true" [autoFocus]="true"></cui-input>
          <div *ngIf="submittedMpnId" class="result-div">
            <div *ngIf="obs$.isLoading" class="spinner-container">
              <mat-spinner class="absolute-centered" mode="indeterminate" [diameter]="25"></mat-spinner>
            </div>
            <cui-input
              [label]="'Partner Name'"
              *ngIf="!obs$.isLoading && obs$.retrievedPartnerName !== null"
              formControlName="partnerName"
              [copyBtn]="true"
            ></cui-input>
            <div *ngIf="!obs$.isLoading && !obs$.retrievedPartnerName" class="partner-not-found">
              Partner with MPN ID {{ submittedMpnId }} not found.
            </div>
          </div>
        </form>
      </cui-card-body>
      <cui-card-footer>
        <cui-button (clicked)="onCancelClick()">{{ DIALOG_NO }}</cui-button>
        <cui-button-cta [disabled]="(form && (form.disabled || !form.valid || !form.dirty)) || obs$.isLoading" (clicked)="onAddClick()">
          Check</cui-button-cta
        >
      </cui-card-footer>
    </cui-card>
  `,
  styles: `
    .result-div {
      position: relative;
      height: 2.75rem;
      .spinner-container .absolute-centered {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
      }
      .partner-not-found {
        color: var(--color-danger);
        padding: 0.5rem 0 0 0.75rem;
        height: 100%;
      }
    }
  `,
})
export class MpnIdCheckDialogComponent {
  isLoading$: Observable<boolean> = this.store.pipe(select(cspUtilSelectors.selectIsPartnerNameRetrievalFromMicrosoftLoading)).pipe(
    tap(() => {
      this.form = this.formBuilder.group({
        mpnId: this.formBuilder.control(this.submittedMpnId ?? '', {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(7), CuiValidators.noEmptyStrings(), CuiValidators.integersOnly],
        }),
        partnerName: this.formBuilder.control(
          { value: '', disabled: true },
          {
            nonNullable: true,
          },
        ),
      });
    }),
  );

  retrievedPartnerName$: Observable<string | null> = this.store.pipe(
    select(cspUtilSelectors.selectRetrievedPartnerNameFromMicrosoft),
    tap(partnerName => {
      if (partnerName) this.form.controls.partnerName.setValue(partnerName);
    }),
  );

  form!: FormGroup<MpnIdCheckForm>;
  submittedMpnId: string | null = null;

  readonly DIALOG_NO = DIALOG_NO;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly dialogRef: DialogRef,
    private readonly store: Store<AppState>,
  ) {}

  onAddClick(): void {
    if (this.form && this.form.valid) {
      const { mpnId } = this.form.getRawValue();
      this.submittedMpnId = mpnId;
      this.store.dispatch(cspUtilActions.getPartnerNameByMpnId({ req: { mpnId } }));
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
