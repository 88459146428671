import { Component, OnInit } from '@angular/core';
import { identityActions } from '@appState';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app-state/app.store';

@Component({
  selector: 'ocf-root',
  template: '<router-outlet></router-outlet>',
  standalone: false,
})
export class MainComponent implements OnInit {
  constructor(
    private store: Store<AppState>,
    private ngSelectConfig: NgSelectConfig,
  ) {
    this.ngSelectConfig.appendTo = 'body';
    this.ngSelectConfig.appearance = 'outline';
  }

  ngOnInit(): void {
    this.store.dispatch(identityActions.checkAuth());
  }
}
