.spinner-container .absolute-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.article-container {
  margin: 0px auto;
  max-width: 900px;
  padding: 30px 30px 30px 20px;
  .categories-container {
    display: flex;
    margin: 0px 0px 10px 0px;
    flex-wrap: wrap;
    .category {
      border-radius: 10px;
      padding: 4px 10px;
      background-color: #9aceff;
      text-wrap: wrap;
      font-size: 14px;
      margin: 0px 10px 5px 0px;
      display: flex;
    }
  }

  .subject {
    font-size: 24px;
    font-weight: bold;
    margin: 0px 0px 10px 0px;
    line-height: normal;
  }

  .date {
    margin: 0px 0px 20px 0px;
    font-size: 14px;
  }

  .rich-text img {
    max-width: 100%;
    object-fit: contain;
  }

  .keywords-container {
    display: flex;
    flex-wrap: wrap;
    .label {
      font-weight: bold;
      font-size: 14px;
      margin: auto 10px auto 0px;
    }
    .keyword-item {
      border-radius: 10px;
      padding: 3px 7px;
      background-color: #ffb978;
      text-wrap: wrap;
      font-size: 13px;
      margin: auto 10px 5px 0px;
      display: flex;
    }
  }
}
